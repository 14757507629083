exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-blog-jsx-content-file-path-blog-2024-12-02-best-personal-training-app-index-mdx": () => import("./../../../src/templates/blog.jsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/blog/2024-12-02-best-personal-training-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-jsx-content-file-path-blog-2024-12-02-best-personal-training-app-index-mdx" */),
  "component---src-templates-blog-jsx-content-file-path-blog-2025-01-01-how-can-an-app-personal-index-mdx": () => import("./../../../src/templates/blog.jsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/blog/2025-01-01-how-can-an-app-personal/index.mdx" /* webpackChunkName: "component---src-templates-blog-jsx-content-file-path-blog-2025-01-01-how-can-an-app-personal-index-mdx" */),
  "component---src-templates-blog-jsx-content-file-path-blog-2025-01-02-features-software-business-management-index-mdx": () => import("./../../../src/templates/blog.jsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/blog/2025-01-02-features-software-business-management/index.mdx" /* webpackChunkName: "component---src-templates-blog-jsx-content-file-path-blog-2025-01-02-features-software-business-management-index-mdx" */),
  "component---src-templates-case-study-jsx-content-file-path-blog-2025-01-22-tom-serghi-case-study-index-mdx": () => import("./../../../src/templates/case_study.jsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/blog/2025-01-22-tom-serghi-case-study/index.mdx" /* webpackChunkName: "component---src-templates-case-study-jsx-content-file-path-blog-2025-01-22-tom-serghi-case-study-index-mdx" */)
}

